<template lang="pug">
.file-manager
  h1.file-manager__title Файловый менеджер
  FileErrors(:maybeUpdated="maybeUpdated" @reset-maybe-updated="maybeUpdated = false")
  file-manager(:settings="settings" @click.native="maybeUpdated = true")
</template>
<script>
import { getToken } from '@/utils/auth'
import FileErrors from './FileErrors'
export default {
  name: 'FileManagerPage',
  components: { FileErrors },
  data() {
    return {
      maybeUpdated: false,
      settings: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${getToken()}`,
        },
        baseUrl: `${process.env.VUE_APP_API_BASE}/file-manager/`,
        windowsConfig: 2,
        lang: 'ru'
      },
    }
  },
}
</script>
<style lang="sass">
.file-manager
  padding: 20px
  &__title
    color: #2c3e50
  .fm-navbar .col-auto:last-child .btn-group:last-child
    display: none
</style>

<template lang="pug">
.file-errors
  .file-errors__header
    .file-errors__title Проверка отсутствующих файлов в базе данных
    .file-errors__update(@click="fetch" :class="{ 'file-errors__update_disabled': updating }") Обновить
    .file-errors__actual(v-if="maybeUpdated") (данные могут быть неактуальны, нажмите "Обновить")
  el-collapse.file-errors__list(v-if="errors.length" v-model="openedErrors")
    el-collapse-item.file-errors__item(v-for="item in errors" :key="item.id" :title="`${item.path} (ID: ${item.id})`" :name="item.id")
      .file-errors__usages-title Использование файла:
      .file-errors__usages(v-html="item.usages")
  .file-errors__empty(v-else-if="!updating") Ошибки отсутствуют
</template>
<script>
import {get} from '@/utils/api'

export default {
  name: 'FileErrors',
  props: {
    maybeUpdated: { type: Boolean, default: false }
  },
  data(){
    return {
      openedErrors: [],
      errors: [],
      updating: true
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.updating = true
      const { success, items } = await get('files/file-errors')
      if (success) {
        this.errors = items
        this.$emit('reset-maybe-updated')
      }
      this.updating = false
    }
  }
}
</script>
<style lang="sass">
.file-errors
  &__header
    display: flex
    flex-flow: row nowrap
  &__title
    font-weight: bold
  &__update
    cursor: pointer
    margin-left: 30px
    color: #1c6ca1
    transition: color 0.3s
    &:hover
      color: #0054ff
    &_disabled
      color: gray
      &:hover
        color: #919191
  &__actual
    margin-left: 30px
</style>
